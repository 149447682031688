'use strict'
let leftContainer = document.querySelector(".left-container")
let rightContainer = document.querySelector(".right-container")
document.addEventListener('DOMContentLoaded', function() {
  // if (/iPhone|iPod|iPad|Android/i.test(navigator.userAgent) || (navigator.userAgent.includes('Macintosh') && 'ontouchend' in document)){
  if (/iPad/i.test(navigator.userAgent) || (navigator.userAgent.includes('Macintosh') && 'ontouchend' in document)){
    var mql = window.matchMedia( "(orientation: portrait)" );
    mql.addListener( onOrientationChange );
    if ( mql.matches ) {
      if(leftContainer) {
        if(leftContainer.classList.contains("col-md-8")) {
          leftContainer.classList.remove("col-md-8")
          leftContainer.classList.add("orign-col-md-8")
          leftContainer.classList.add("col-md-12")
        }
        else if(leftContainer.classList.contains("col-md-6")) {
          leftContainer.classList.remove("col-md-6")
          leftContainer.classList.add("orign-col-md-6")
          leftContainer.classList.add("col-md-12")
        }
      }
      if(rightContainer) {
        if(rightContainer.classList.contains("col-md-4")) {
          rightContainer.classList.remove("col-md-4")
          rightContainer.classList.add("origin-col-md-4")
          rightContainer.classList.add("col-md-12")
        }
        else if(rightContainer.classList.contains("col-md-6")) {
          rightContainer.classList.remove("col-md-6")
          rightContainer.classList.add("origin-col-md-6")
          rightContainer.classList.add("col-md-12")
        }
      }
    }
  }
})
function onOrientationChange ( mql ) {
  // landscapeを基本とし、Prefixに "originn-" を付けてCSSが適用されないようにする
  // portrait 時に左右のコンテナを縦並びに切り替える
  if ( mql.matches ) {
    // portrait
    if(leftContainer) {
      if(leftContainer.classList.contains("col-md-8")) {
        leftContainer.classList.remove("col-md-8")
        leftContainer.classList.add("orign-col-md-8")
        leftContainer.classList.add("col-md-12")
      }
      else if(leftContainer.classList.contains("col-md-6")) {
        leftContainer.classList.remove("col-md-6")
        leftContainer.classList.add("orign-col-md-6")
        leftContainer.classList.add("col-md-12")
      }
    }
    if(rightContainer) {
      if(rightContainer.classList.contains("col-md-4")) {
        rightContainer.classList.remove("col-md-4")
        rightContainer.classList.add("origin-col-md-4")
        rightContainer.classList.add("col-md-12")
      }
      else if(rightContainer.classList.contains("col-md-6")) {
        rightContainer.classList.remove("col-md-6")
        rightContainer.classList.add("origin-col-md-6")
        rightContainer.classList.add("col-md-12")
      }
    }
  } else {
    // landscape
    if(leftContainer) {
      if(leftContainer.classList.contains("orign-col-md-8")) {
        leftContainer.classList.remove("orign-col-md-8")
        leftContainer.classList.remove("col-md-12")
        leftContainer.classList.add("col-md-8")
      }
      else if(leftContainer.classList.contains("orign-col-md-6")) {
        leftContainer.classList.remove("orign-col-md-6")
        leftContainer.classList.remove("col-md-12")
        leftContainer.classList.add("col-md-6")
      }
    }
    if(rightContainer) {
      if(rightContainer.classList.contains("orign-col-md-4")) {
        rightContainer.classList.remove("orign-col-md-4")
        rightContainer.classList.remove("col-md-12")
        rightContainer.classList.add("col-md-4")
      }
      else if(rightContainer.classList.contains("orign-col-md-6")) {
        rightContainer.classList.remove("orign-col-md-6")
        rightContainer.classList.remove("col-md-12")
        rightContainer.classList.add("col-md-6")
      }
    }
  }
  // CSS再計算のためリロード
  location.reload()
}
